import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Scrollbar from '../../../component/scrollbar/Scrollbar';
import TablePagination from '../../../component/TablePagination';
import { StyledTableCell, StyledTableRow } from '../../../component/tableStyle';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDeviceLogsQuery } from '../../../../globalState/devices/deviceApis';
import { setDeviceListPageNo } from '../../../../globalState/devices/deviceSlices';
import DeviceLogsTableHead from './DeviceLogsTableHead';
import DeviceLogsTableRow from './DeviceLogsTableRow';
import DeviceLogsTableToolbar from './DeviceLogsTableToolbar';


function DeviceLogs() {

  const dispatch = useDispatch()

  const { deviceUniqueID, deviceID, deviceActionType, pageNo } = useSelector(state => state.device);

  const { data: deviceLogData, isSuccess: deviceLogSuccess, isLoading } = useGetDeviceLogsQuery({ page: pageNo, deviceID, action: deviceActionType, uniqueId: deviceUniqueID });

  const allDeviceLogData = deviceLogSuccess && deviceLogData?.data?.data

  const paginationData = deviceLogSuccess && deviceLogData?.data;
  const { last_page } = paginationData;

  const handlePageChange = (event, value) => {
    dispatch(setDeviceListPageNo(value));
  };


  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={3}
        >
          <Typography variant="h4" color="white">Device Logs</Typography>
        </Stack>
        <Card sx={{ bgcolor: "#3e403d0f", boxShadow: '0px 4px 12px rgba(87, 179, 62, 0.2)' }}>
          <DeviceLogsTableToolbar />
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 300, padding: 4 }}>
              <Typography color="white" sx={{ mt: 2 }}>Loading...</Typography>
            </Stack>
          ) : (<Scrollbar>
            <TableContainer sx={{ overflow: 'unset' }}>
              <Table sx={{ minWidth: 800 }}>
                <DeviceLogsTableHead allDeviceLogData={allDeviceLogData} />
                <TableBody>
                  {allDeviceLogData.length > 0 ? (
                    <DeviceLogsTableRow
                      allDeviceLogData={allDeviceLogData}
                      currentPageNo={pageNo}
                    />
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10} align="center" sx={{ border: "1px solid red", padding: "2rem" }}>
                        <Typography color="white">No Data Found</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          )}
          {allDeviceLogData.length > 0 && <TablePagination
            count={last_page}
            page={pageNo}
            onPageChange={handlePageChange}
          />}
        </Card>
      </Container>
    </>
  );
}

export default DeviceLogs;