import { fontFamily } from "@mui/system";

export const inputStyle = {
    backgroundColor: "#1d282c",
    color: "white",
    fontfamily: 'sans-serif',
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
    }
}