import React from 'react'
import {Box, Typography} from "@mui/material";
import { Container } from '@mui/system';

function About() {
  return (
    <>
<Box className ='sevices_bg'>
</Box>
<Container>
 <Typography sx={{margin:'20px 0px',fontWeight:'700'}} variant='h5'>About Us</Typography>
</Container>
</>
  )
}

export default About