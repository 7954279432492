export const permissionData = [
    {
        ID: 1,
        description: "I am description",
        Name: "Permission",
        Status: "Active"
    },
    {
        ID: 2,
        description: "I am description",
        Name: "Permission",
        Status: "Inactive"
    },
    {
        ID: 3,
        description: "I am description",
        Name: "Permission",
        Status: "Active"
    },
    {
        ID: 4,
        description: "I am description",
        Name: "Permission",
        Status: "Active"
    },
    {
        ID: 5,
        description: "I am description",
        Name: "Permission",
        Status: "Inactive"
    },
    {
        ID: 6,
        description: "I am description",
        Name: "Permission",
        Status: "Active"
    },
    {
        ID: 7,
        description: "I am description",
        Name: "Permission",
        Status: "Inactive"
    },
    {
        ID: 8,
        description: "I am description",
        Name: "Permission",
        Status: "Active"
    },
    {
        ID: 9,
        description: "I am description",
        Name: "Permission",
        Status: "Active"
    },
    {
        ID: 10,
        description: "I am description",
        Name: "Permission",
        Status: "Inactive"
    }
]

export const headLabel = [
    { id: 'name', label: 'Name' },
    { id: 'description', label: 'Description' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action', align: "center" }
]