export const menuList = [
    {
        name: "Home",
        // icon: <DashboardIcon />,
        path: "/"
    },
    {
        name: "About",
        // icon: <ViewCarouselIcon />,
        path: "/about"
    },
    {
        name: "Services",
        // icon: <LocalOfferIcon />,
        // arr: [
        //     {
        //         name: "Ayurveda",
        //         path: "/ourBusiness/ayurveda",
        //     },
        //     {
        //         name: "Nutraceutical",
        //         path: "/ourBusiness/Nutraceutical",
        //     }
        // ]
    },
    // {
    //     name: "Servies",
    //     // icon: <ViewCarouselIcon />,
    //     path: "/Servies"
    // },
    {
        name: "Blog",
        // icon: <NoteIcon />,
        path: "/Blog",
    },
    {
        name: "Contact",
        // icon: <LogoutIcon />,
        path: "/contactUs",
    },

]