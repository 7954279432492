import React from 'react'
import {Box, Typography} from "@mui/material";
import { Container } from '@mui/system';

function Services() {
  return (
    <>
  <Box className ='sevices_bg'>
  </Box>
  <Container>
   <Typography sx={{margin:'20px 0px',fontWeight:'700'}} variant='h5'>All Services</Typography>
  </Container>
  </>
  )
}
export default Services