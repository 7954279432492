import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { headLabel } from './deviceLogsData';
import { StyledTableCell } from '../../../component/tableStyle';

// ----------------------------------------------------------------------

function DeviceLogsTableHead() {

    return (
        <TableHead>
            <TableRow>
                {
                    headLabel.map((headCell) => (
                        <StyledTableCell
                            sx={{ width: headCell?.width, minWidth: headCell?.minWidth, whiteSpace: 'nowrap', border: "1px solid", color: "white", fontSize: "1rem", fontWeight: "700" }}
                        >
                            <TableSortLabel hideSortIcon>
                                {headCell?.label}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );
}

export default DeviceLogsTableHead;