import { StyledTableCell, StyledTableRow } from '../../../component/tableStyle';
import { useState, useEffect } from 'react';
import { List, ListItem, Skeleton } from '@mui/material';
import Label from '../../../component/lable/Lable';

function DeviceLogsTableRow({ allDeviceLogData }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 1000);
        return () => clearTimeout(timer);
    }, []);


    const renderNestedData = (data) => {
        // Check if data is empty or invalid
        if (!data || (typeof data === 'object' && Object.keys(data).length === 0)) {
            return (
                <ListItem sx={{ whiteSpace: "nowrap", fontStyle: "italic", color: "gray" }}>
                    No Data Available
                </ListItem>
            );
        }

        // For non-object or null data
        if (typeof data !== 'object' || data === null) {
            const isDate = (typeof data === 'string' && !isNaN(Date.parse(data)));

            return (
                <ListItem
                    sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {isDate ? new Date(data).toLocaleString() : data}
                </ListItem>
            );
        }

        // For object data, recursively process each entry
        return (
            <List>
                {Object.entries(data).map(([key, value], index) => (
                    <ListItem
                        key={index}
                        sx={{
                            p: ".3rem",
                            gap: ".4rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <strong>
                            {key}{typeof value === "object" ? ":-" : ":"}
                        </strong>
                        {typeof value === 'object'
                            ? renderNestedData(value)
                            : (typeof value === 'string' && !isNaN(Date.parse(value)))
                                ? new Date(value).toLocaleString()
                                : value}
                    </ListItem>
                ))}
            </List>
        );
    };


    return (
        <>
            {allDeviceLogData.length > 0 &&
                allDeviceLogData.map((data) => (
                    <StyledTableRow key={data.id}>
                        <StyledTableCell color={"#222245"} sx={{ border: "1px solid" }}>
                            {loading ? <Skeleton sx={{ bgcolor: '#57b33e3d' }} animation="pulse" /> : data?.deviceID}
                        </StyledTableCell>
                        <StyledTableCell color={"#222245"} sx={{ border: "1px solid" }}>
                            {loading ? <Skeleton sx={{ bgcolor: '#57b33e3d' }} animation="pulse" /> : data?.uniqueId}
                        </StyledTableCell>
                        <StyledTableCell color={"#222245"} sx={{ border: "1px solid" }}>
                            {loading ? <Skeleton sx={{ bgcolor: '#57b33e3d' }} animation="pulse" /> : data?.action}
                        </StyledTableCell>
                        <StyledTableCell color={"#222245"} sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            border: "1px solid"
                        }}>
                            {loading ? (
                                <Skeleton sx={{ bgcolor: '#57b33e3d' }} animation="pulse" />
                            ) : (
                                renderNestedData(data?.raw_data)
                            )}
                        </StyledTableCell>
                        <StyledTableCell color={"#222245"} sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            border: "1px solid"
                        }}>
                            {loading ? (
                                <Skeleton sx={{ bgcolor: '#57b33e3d' }} animation="pulse" />
                            ) : (
                                renderNestedData(data?.response_data?.payload)
                            )}
                        </StyledTableCell>
                        <StyledTableCell color={"#222245"} sx={{ border: "1px solid" }}>
                            {loading ? <Skeleton sx={{ bgcolor: '#57b33e3d' }} animation="pulse" /> : new Date(data?.status_last_updated).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell sx={{ border: "1px solid" }}>
                            <Label color={data?.charger_status === "online" ? 'success' : 'error'} >{loading ? <Skeleton sx={{ bgcolor: data?.charger_status === "online" ? 'success' : 'error' }} animation="pulse" /> : (data?.charger_status === "online" ? 'Active' : 'Inactive')}</Label>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
        </>
    );
}

export default DeviceLogsTableRow;